<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bxs-edit-location"></i>
            {{ $t("location_groups.label") }}
          </template>
          <form-input 
            label="common.name" 
            icon="bx-purchase-tag"
            v-model="item.name"
            rules="required"
          />
        </b-card>
      </div>
      <div class="col mb-4">
      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import FormInput from "@/components/Form/Input";
export default {
  extends: ItemEdit,
  components: { ItemEditTemplate, FormInput },
};
</script>